/* stylelint-disable no-duplicate-selectors  */
.react-datepicker__navigation--previous {
  border-right-color: #dbdbe2 !important;
}

.react-datepicker__navigation--previous:focus {
  outline: none !important;
}

.react-datepicker__navigation--next {
  border-left-color: #dbdbe2 !important;
  outline: none;
}

.react-datepicker__navigation--next:focus {
  outline: none !important;
}

.react-datepicker__day--selected.react-datepicker__day--weekend,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  color: white !important;
  border-radius: 15px !important;
  background-color: #8a00fc !important;
}

.react-datepicker__day--selected.react-datepicker__day--weekend:focus,
.react-datepicker__day--keyboard-selected:focus,
.react-datepicker__day--selected:focus,
.react-datepicker__day:focus {
  outline: none !important;
}

.react-datepicker {
  border-color: #ebebf0 !important;
  border-radius: 0 !important;
}

.react-datepicker__header {
  background: white !important;
  font-family: 'Gotham SSm', sans-serif !important;
  padding-top: 10px !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  border-top-color: #dbdbe2 !important;
  border-width: 4px !important;
}

.react-datepicker__current-month--hasMonthDropdown,
.react-datepicker__day-name,
.react-datepicker__day {
  font-weight: 400 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.react-datepicker__day:hover {
  border-radius: 15px !important;
}

.react-datepicker__day--selected.react-datepicker__day--weekend,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--outside-month,
.react-datepicker__day--selected {
  color: white !important;
}

.react-datepicker__day--outside-month,
.react-datepicker__day--outside-month:hover {
  cursor: default !important;
  background: none !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background: white !important;
  border-color: #ebebf0 !important;
}

.react-datepicker__triangle {
  border-bottom-color: white !important;
}

.react-datepicker__triangle::before {
  border-bottom-color: #ebebf0 !important;
  border-top-color: #ebebf0 !important;
}

@media only screen and (min-width: 1080px) {
  .react-datepicker__triangle {
    left: 184px !important;
  }
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  line-height: 1;
  margin-top: 10px;
  border-color: #dbdbe2;
  font-size: 14px;
}

.react-datepicker__month-select:focus,
.react-datepicker__year-select:focus {
  border-color: #8a00fc;
}
