#ccc h3,
#ccc p {
  font-size: 12px !important;
}

@media only screen and (min-width: 768px) {
  .ccc-notify-buttons,
  #ccc h3,
  #ccc p {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 768px) and (max-device-width: 1024px) {
  #ccc-notify {
    flex-direction: column !important;
  }

  .ccc-notify-text {
    padding-bottom: 24px;
  }
}
