#notice > p:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

#notice > p:first-child > img {
  padding-right: 16px;
}

#notice > p {
  margin-top: 0;
}
