@layer utilities {
  .loading-icon {
    @apply animate-spin h-5 w-14;
  }

  .loading-icon > path {
    @apply opacity-75;
  }

  .loading-icon > circle {
    @apply opacity-25;
  }
}
