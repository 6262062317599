@tailwind utilities;

@layer utilities {
  .hyphens-auto {
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
  }

  .underline-offset {
    text-underline-offset: 4px;
  }

  .loading-icon {
    @apply animate-spin h-5 w-14;
  }

  .loading-icon > path {
    @apply opacity-75;
  }

  .loading-icon > circle {
    @apply opacity-25;
  }

  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .imageContainer > div {
    @apply h-full;
  }

  .imageContainer > div > div {
    @apply h-full;
  }

  .text-gradient-animated {
    background: linear-gradient(
      300deg,
      #686775 20%,
      #494851 40%,
      #494851 60%,
      #686775 80%
    );
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 10s linear infinite;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }

  .truncate-2-liner {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .maxAppWidth {
    max-width: 2560px;
  }
}

.boxShadowTop {
  box-shadow: 0 -14px 14px rgb(0 0 0 / 30%);
}

.boxShadowBottom {
  box-shadow: 0 14px 14px rgb(0 0 0 / 20%);
}

.grabbyHand {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbyHand:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
