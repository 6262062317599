@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import './utilities.css';
@import './rootStyles.css';
@import './typography.css';
@import './loadingIcon.css';
@import './pdp.css';
@import './nprogress.css';
@import './datePicker.css';
@import './shareApiPolyfill.css';
@import './notice.module.css';
@import './cookieConsent.css';

:root {
  --globalBannerHeight: 45px;
}
