@layer components {
  .h1 {
    @apply text-5xl lg:text-6xl xl:text-7xl font-bold leading-tight xl:leading-tight tracking-tighter;
  }

  .h2 {
    @apply text-4xl lg:text-5xl xl:text-6xl font-bold leading-none md:leading-tight xl:leading-tight tracking-tighter;
  }

  .h3 {
    @apply text-3xl lg:text-4xl xl:text-5xl font-bold leading-9 xl:leading-tight tracking-tighter;
  }

  .h4 {
    @apply text-2xl lg:text-3xl xl:text-4xl font-bold leading-8 tracking-tighter;
  }

  .h5 {
    @apply text-2xl lg:text-3xl xl:text-4xl font-semibold leading-8 tracking-tighter;
  }

  .h6 {
    @apply text-xl lg:text-2xl xl:text-3xl font-semibold leading-7 tracking-tighter;
  }

  .leading-text {
    @apply text-base 2xl:text-lg leading-snug 2xl:leading-snug font-semibold tracking-tight;
  }

  .leading-text-bolder {
    @apply text-base 2xl:text-lg leading-snug 2xl:leading-snug font-bold tracking-tight;
  }

  .base-text {
    @apply text-sm 2xl:text-base leading-5 2xl:leading-snug font-normal tracking-tight text-nzxt-light-grey-800;
  }
}
